import type { GetProductResponse } from '@vue-storefront/prestashop-api';
import { useSdk } from '~/sdk';

export const useAddToCart = () => {
  const state = reactive({
    loading: false,
    error: null,
    data: null,
  });

  const { addOrRemoveFromCart } = useAnalytics();

  const generalStore = useGeneralStore();

  const { data: cart, refresh: refreshCart } = useCart();

  const execute = async (params: {
    id_product: GetProductResponse['psdata']['id_product'];
    id_product_attribute: GetProductResponse['psdata']['id_product_attribute'];
    qty: number;
  }) => {
    if (!params) {
      return;
    }

    state.loading = true;

    const data = await useSdk().commerce.updateCartItem({
      id_product: params.id_product,
      id_product_attribute: params.id_product_attribute,
      qty: params.qty,
    });

    addOrRemoveFromCart({ ...params }, 'add_to_cart');

    state.data = data;
    state.loading = false;

    Promise.all([refreshCart()]);

    generalStore.toggleCartSidebar();

    return data;
  };

  return {
    ...toRefs(state),
    execute,
  };
};
